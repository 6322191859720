import React, { useCallback, useEffect, useState } from 'react';
import logoApp from '../assests/images/logomin.png';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import moment from "moment";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, setDoc, doc, getDocs } from 'firebase/firestore';
import { getStorage, ref as storageRef } from "firebase/storage";
import CustomWebcam from './components/CustomWebcam';
import axios from 'axios';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {v4 as uuidv4} from 'uuid';



const Appointment = () => {

    // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA1V8tMFAk43K2knTIusf1rjoE-Z1zR8X8",
    authDomain: "dbsapp-1e68c.firebaseapp.com",
    databaseURL: "https://dbsapp-1e68c-default-rtdb.firebaseio.com",
    projectId: "dbsapp-1e68c",
    storageBucket: "dbsapp-1e68c.appspot.com",
    messagingSenderId: "305681398524",
    appId: "1:305681398524:web:ba8cf5701b2a964dc53579",
    measurementId: "G-3DTQEPR06S"
  };
  
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
const db = getFirestore(app);
const storage = getStorage();

    const today = dayjs().format('DD/MM/YYYY');
    const [valueDay, setValueDay] = useState(dayjs());

    const [agree, setAgree] = useState(false);
    ///const [dateMeet, setDateMeet] = useState(today);

    const handleAgreeChange = () => setAgree(!agree);
   /// const handledateMeetChange = (date) => setDateMeet(date);
    const [idCardRecto, setIdCardRecto] = useState(null);
    const [idCardVerso, setIdCardVerso] = useState(null);

    const [lastId, setLastId] = useState(-1);

    let myuuid = uuidv4();    

    const arrayAvailableHours = [
        {
            label : "08h-10h",
            id : 0,
            hour :8,
            min : 0
        },
        {
            label : "10h30-12h30",
            id : 1,
            hour :10,
            min : 30
        },
        {
            label : "13h-15h",
            id : 2,
            hour :13,
            min : 0
        }/* ,
        {
            label : "15h30-17h30",
            id : 3,
            hour :15,
            min : 30
        } */
    ];
    
    const [availableHours, setAvailableHours] = useState(arrayAvailableHours)

    const handleSetLastId = (id) =>{
        setLastId(id);
        console.log("id", id);
        
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm()


      const sendDataToEmail = async (data) =>{
        
        try {
            const response = await fetch('https://api.fragranceyok.com/auth/invite-mail',{
           // const response = await fetch('http://localhost:8080/auth/invite-mail',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': 'hana-7879'
                },
                body: JSON.stringify({
                    email : data.email,
                    firstname : data.firstname,
                    lastname : data.lastname,
                    address: data.address,
                    phone: data.phone,
                    datetime: data.hour
                })
            });
    
        
                const datas = await response.json();
               /*  if(datas.success){
                    toast.success('Modèle ajouté avec succès!')
                    hideModelMessageModalFunc()
                    getAllModelSMS()
                } */
        } catch (error) {
            /* setLoadingModel(false)
            toast.error('Echec ') */
        }
    }

    useEffect( () =>{

        const getDaysAvailable = async () =>{
            await getDocs(collection(db, `appointments-${valueDay.format('DD/MM/YYYY').replaceAll("/","_")}`))
                .then((querySnapshot)=>{               
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id:doc.id }));

                        if(newData.length>0){
                                let arrayResult = arrayAvailableHours;
                                newData.forEach(newDataValue => {
                                arrayResult =arrayResult.filter(item =>((item.label!==newDataValue.meetHour) && (valueDay.hour(parseInt(item.hour)).minute(parseInt(item.min)).second(0).isAfter(dayjs()))))
                                })
                                
                            setAvailableHours(arrayResult)                            
                        }else{
                            let arrayResult = arrayAvailableHours;
                           // arrayResult = valueDay.day()===0?[]:arrayAvailableHours;
                            setAvailableHours(arrayResult)
                        }
                                                     
                });
            }

            getDaysAvailable();
        
    }, [valueDay])

    const sendImagesData = async(data, selectedDate)=>{

        const formData = new FormData();
        var fileidCardRecto = new File([DataURIToBlob(idCardRecto)], 'idcardrecto.png');
        var fileidCardVerso = new File([DataURIToBlob(idCardVerso)], 'idcardverso.png');

        formData.append("idCardRecto", fileidCardRecto);
        formData.append("idCardVerso", fileidCardVerso);
            
        //axios.post('http://localhost:8080/auth/upload-card', 
        axios.post('https://api.fragranceyok.com/auth/upload-card',    
            formData,
            {
                headers:{
                    'Content-Type':'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'token': 'hana-7879'
                }
            }
        ).then(async (response) => {
            if(response.status===200){

               // console.log(response.data.idCardRecto, response.data.idCardVerso);
                try {
                    data.idrecto = response.data.idCardRecto
                    data.idverso = response.data.idCardVerso
                    const myDocRef = doc(collection(db, `appointments-${valueDay.format('DD/MM/YYYY').replaceAll("/","_")}`), `appointments-${valueDay.format('DD/MM/YYYY').replaceAll("/","_")}-${myuuid.replaceAll("-","")}`);
                    const docRef = await setDoc(myDocRef, data);
                   // console.log("Document written with ID: ", docRef.id);
                    data.lastname=data.name;
                    data.hour=moment(selectedDate).format('YYYY-MM-DD HH:mm:ss');
                    
                    await sendDataToEmail(data);
                    
                    Swal.fire({
                        title: "Sended!",
                        text: "Your appointment has been sent.",
                        icon: "success"
                      }).then(() =>{
                        window.location.reload();

                      });
                  } catch (e) {
                    console.error("Error adding document: ", e);
                  }
            }
        }).catch((error) =>{
            console.log(error);
            
        })
    }

    const handleChangeValueDay = (newValue) =>{
        setValueDay(newValue)        
    }

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        console.log("mimeString", mimeString);
        
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }

    const submit = (data) => {

         if(!idCardRecto || !idCardVerso){
            Swal.fire({
              icon: "error",
              title: "Oops ...",
              text: "please set your Id cards"
            });

            return;
        }

        if(lastId===-1){
            Swal.fire({
                icon: "error",
                title: "Oops ...",
                text: "please choose your appointment time"
              });
  
              return;
        }

        const availableHour = availableHours.filter((data) =>data.id===lastId)[0];        
 
        const selectedDate = new Date(valueDay).setHours(availableHour.hour, availableHour.min, 0)
        data.meet = moment(selectedDate).format('DD/MM/YYYY HH:mm:ss');
        data.meetHour = availableHour.label;
        
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, send it!"
          }).then(async (result) => {
            if (result.isConfirmed){
                await sendImagesData(data, selectedDate);
                
            }
          });

       
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='container justify-content-center'>
                <div className="justify-content-center d-flex">
                    <img className='mb-5' src={logoApp} />
                </div>

                <p className='fs-2 fw-bold text-dark text-center'>Driving support reservation </p>

                <form onSubmit={handleSubmit(submit)}>
                    
                    <div className='form-group mt-3'>
                        <label htmlFor='firstname'>First name <abbr className='text-danger'>*</abbr></label>
                        <input id='firstname' type='text' className='form-control' name='firstname' placeholder='John' {...register("firstname", { required: true })} />
                        {errors.firstname?.type === "required" && (<p className='text-danger' role="alert">First name is required</p>)}
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='name'>Last name <abbr className='text-danger'>*</abbr></label>
                        <input id='name' type='text' className='form-control' placeholder='Doe' {...register("name", { required: true })} />
                        {errors.name?.type === "required" && (<p className='text-danger' role="alert">Name is required</p>)}
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='email'>E-mail <abbr className='text-danger'>*</abbr></label>
                        <input id='email' type='email' className='form-control' placeholder='john.doe@xxx.com' {...register("email", { required: true })} />
                        {errors.email?.type === "required" && (<p className='text-danger' role="alert">E-mail is required</p>)}
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='phone'>Phone number <abbr className='text-danger'>*</abbr></label>
                        <input id='phone' type='phone' className='form-control' placeholder='+1 ' {...register("phone", { required: true })} />
                        {errors.email?.type === "required" && (<p className='text-danger' role="alert">Phone number is required</p>)}
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='address'>Full address <abbr className='text-danger'>*</abbr></label>
                        <input id='address' type='text' className='form-control' placeholder='street, town, country' {...register("address", { required: true })} />
                        {errors.address?.type === "required" && (<p className='text-danger' role="alert">Address is required</p>)}
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='address'>Language <abbr className='text-danger'>*</abbr></label>
                        <select className='form-control' defaultValue={"english"} {...register("lang", { required: true })}>
                            <option value={"english"}>English</option>
                            <option value={"french"}>French</option>
                            <option value={"punjabi"}>Punjabi</option>
                            <option value={"hindi"}>Hindi</option>
                            <option value={"spanish"}>Spanish</option>
                            <option value={"urdu"}>Urdu</option>
                            <option value={"pashto"}>Pashto</option>
                            <option value={"rabic"}>Arabic</option>
                        </select>
                        {errors.address?.type === "required" && (<p className='text-danger' role="alert">Address is required</p>)}
                    </div>

                    <div className='vstack gab-2 mt-3'>
                    
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateCalendar', 'DigitalClock']}>
                                <DemoItem label={<span>Appointment date <abbr className='text-danger'>*</abbr></span>}>
                                    <DateCalendar value={valueDay} 
                                    onChange={handleChangeValueDay} 
                                    disablePast={true}
                                    />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                        <div className='row'>
                            {
                                availableHours.map((hour)=>(
                                    <button type='button' key={hour.id} onClick={() => handleSetLastId(hour.id)} className={`btn ${hour.id==lastId?'btn-primary':'btn-secondary'} col-sm-3 col-3 m-2`}>{hour.label}</button>
                                ))
                            }
                        </div>
                    </div>

                    <div className='form-group mt-3'>
                        <label htmlFor='address'>Have you ever driven a car ? (If your answer is NO, we can not serve you) <abbr className='text-danger'>*</abbr></label>
                        <div className='hstack gab-2 mt-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="yesRadio" value="yes" {...register("haseverdriven")} />
                            <label className="form-check-label" htmlFor="yesRadio">
                                Yes
                            </label>
                        </div>
                        <div className="form-check mx-3">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="noRadio" value="no" defaultChecked {...register("haseverdriven")} />
                            <label className="form-check-label" htmlFor="noRadio">
                                No
                            </label>
                        </div>
                        </div>
                    </div>

                    <div className="form-group mt-3">
                    <label htmlFor='photoidcard'>Licence Class 7 <abbr className='text-danger'>*</abbr></label>
                    <div id='photoidcard' className="row mt-2">
                        <div className="col-sm-4">
                            <CustomWebcam imgSrc={idCardRecto} setImgSrc={setIdCardRecto} labelText={"Licence Drive Recto"} type={"recto"} />
                        </div>

                        <div className="col-sm-4">
                            <CustomWebcam imgSrc={idCardVerso} setImgSrc={setIdCardVerso} labelText={"Licence Drive Verso"} type={"verso"} />
                        </div>
                    </div>
                    </div>
                    <p className='fs-5 fw-normal mt-3'>By signing this document, you confirm that : </p>

                    <p>1.  You have previous driving experience</p>
                    <p>2.  You agree to be accompanied by HANA (Housing for All Newcomers in Alberta) to futher develop your driving skills
                        and better prepare for your road test
                    </p>
                    <p>3.  You understand that this accompaniment is strictly to support you during driving sessions and does not
                        constitute formal driving instruction
                        
                    </p>

                    <p> Do you accept this terms ?</p>
                    <div className="form-check">
                        <label htmlFor='aggree'>I agree </label>
                        <input id='aggree' type='checkbox' className='form-check-input' onChange={handleAgreeChange} />
                    </div>

                    <div className='justify-content-end d-flex mb-'>
                        <button type='submit' disabled={!agree} className='btn btn-primary mt-4 w-50'>Submit</button>
                    </div>
                </form>
            </div>
        </LocalizationProvider>
    );
};




export default Appointment;
